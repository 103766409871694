import React, { Component } from 'react';
import { LINKS } from "../constants/links";

export default class extends Component {
	componentDidMount() {
		window.location.href = LINKS.WEB_APP_SIGNUP;
	}

	render() {
		return null;
	}
}